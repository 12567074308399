<!-- eslint-disable no-console -->
<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Dluhopisomat` : 'Dluhopisomat'
  },
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico?v1.0.1' },
    { rel: 'preload', type: 'image/svg+xml', href: '/public/sprite.svg' },
  ],
})

const { openCallpageDialog } = useCallpageTracking()
</script>

<template>
  <div class="flex flex-1 flex-col bg-[#fdfdff]">
    <NuxtLayout>
      <NuxtPage :style="{ flexGrow: 1 }" />
    </NuxtLayout>

    <ClientOnly>
      <button
        v-ripple
        class="fixed bottom-[calc(3%+40px)] right-[calc(3%+40px)] flex size-[70px] items-center justify-center rounded-full bg-orange"
        @click="openCallpageDialog"
      >
        <svg
          class="block size-[33px] fill-white transition-all duration-[250ms] ease-in-out"
          fill="none"
          height="19"
          viewBox="0 0 19 19"
          width="19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 1H7L9 6L6.5 7.5C7.57096 9.67153 9.32847 11.429 11.5 12.5L13 10L18 12V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18C12.0993 17.763 8.42015 16.1065 5.65683 13.3432C2.8935 10.5798 1.23705 6.90074 1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1Z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </svg>
      </button>
    </ClientOnly>
  </div>
</template>

<style>
#__nuxt {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sprite-container {
  display: none !important;
}

#sentry-feedback {
  width: initial !important;
}
</style>
