import revive_payload_client_4sVQNw7RlN from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_Ug908EZNww from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_hBLWEPkA3b from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/.nuxt/sentry-client-config.mjs";
import dataLayer_client_sno4qr8tce from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/plugins/dataLayer.client.ts";
import dayjs_timezone_client_tmbxjvgr9i from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/plugins/dayjs-timezone.client.ts";
import directives_8CcCirWtnE from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/plugins/directives.ts";
import storyblokPreview_DYPwibJYs7 from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/plugins/storyblokPreview.ts";
import svgSprite_client_NXGsy3rR9P from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/plugins/svgSprite.client.ts";
import plugin_client_QHzhVBB2Ll from "/home/runner/work/dluhopisomat-web-v2/dluhopisomat-web-v2/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_Ug908EZNww,
  plugin_hBLWEPkA3b,
  plugin_Jozdw60ZsE,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  dataLayer_client_sno4qr8tce,
  dayjs_timezone_client_tmbxjvgr9i,
  directives_8CcCirWtnE,
  storyblokPreview_DYPwibJYs7,
  svgSprite_client_NXGsy3rR9P,
  plugin_client_QHzhVBB2Ll
]